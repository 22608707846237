import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { SEO, devices, Text, Spacing, Stack } from '../components/common';
import HeroSection from '../components/HeroSection';
import Accordion from '../components/Accordion';
import type { PrivacyPage, MetaData } from '../@types/types';

type Props = {
  data: {
    page: PrivacyPage;
    MetaData: MetaData;
  };
  location: Location;
};

type ConsentSummary = {
  date: string;
  device: string;
  os: string;
};

const Privacy = ({ data, location }: Props) => {
  const {
    page: { MetaData, PageTopSection, DataProtectionItem },
  } = data;

  let consentDateGA = null;
  let consentDeviceGA = null;
  let consentOsGA = null;
  let consentDateGTM = null;
  let consentDeviceGTM = null;
  let consentOsGTM = null;

  if (typeof window !== 'undefined') {
    // get the cookie consent data from the local storage
    // google analytics
    consentDateGA = localStorage.getItem('ga-consent-date');
    consentDeviceGA = localStorage.getItem('ga-consent-device');
    consentOsGA = localStorage.getItem('ga-device-os');

    // google tag manager
    consentDateGTM = localStorage.getItem('gtm-consent-date');
    consentDeviceGTM = localStorage.getItem('gtm-consent-device');
    consentOsGTM = localStorage.getItem('gtm-device-os');
  }

  const consentSummary = {
    ga: {
      date: consentDateGA,
      device: consentDeviceGA,
      os: consentOsGA,
    },
    gtm: {
      date: consentDateGTM,
      device: consentDeviceGTM,
      os: consentOsGTM,
    },
  };

  const checkConsent = (consentSummary: ConsentSummary[]) => {
    const result: { [key: string]: string } = {};
    for (const [key, value] of Object.entries(consentSummary)) {
      if (value.date === null || value.device === null || value.os === null) {
        result[key] = 'denied';
      } else {
        result[key] = 'granted';
      }
    }
    return result;
  };

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={MetaData?.MetaTitle}
        description={MetaData?.MetaDescription}
        keywords={MetaData?.metaKeywords}
      />

      <HeroSection topSection={PageTopSection} />

      <AccordionSection>
        <AccordionContainer>
          {DataProtectionItem.map((item, index) => {
            return (
              <Accordion key={index} header={item.Header} text={item.Content} />
            );
          })}
        </AccordionContainer>
      </AccordionSection>

      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/1567fbb9-2872-482f-8763-bd1ee129bfd7/cd.js"
        type="text/javascript"
        async
      ></script>
    </>
  );
};

export default Privacy;

export const privacyQuery = graphql`
  query PrivacyPageQuery {
    page: strapiTietosuoja {
      ...strapiPrivacyFragment
    }
  }
`;

const AccordionSection = styled.div`
  background-color: ${(props) => props.theme.colors.lightBlue};
`;

const AccordionContainer = styled.div`
  width: 70ch;
  margin: auto;
  padding: ${(props) => props.theme.spacing.xxlarge} 0px;

  @media ${devices.laptop} {
    width: 90%;
  }
`;

const CookieItem = styled.li`
  list-style-type: none;
`;

const CookieType = styled(Text)`
  text-decoration: underline;
`;

const ChangeCookies = styled.button`
  ${(props) => props.theme.typography.body};
  border: none;
  background: ${(props) => props.theme.colors.darkBlue};
  color: ${(props) => props.theme.colors.white};
  padding: ${(props) => props.theme.spacing.small}
    ${(props) => props.theme.spacing.default};
  border-radius: 4px;

  &:hover {
    background-color: #36516a;
  }

  &:active {
    outline: none;
  }
`;
